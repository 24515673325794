import React from "react";
import "./err.css";
function Err() {
  return (
    <div>
      <h1>fuck this school 💩💩💩💩💩</h1>
      <p>all bitches they dont even pay for their website. 💩</p>
    </div>
  );
}

export default Err;
